import React from 'react';
//import FBpage from 'facebook-plugins';

class Evasteet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div id="evasteet" className="text_content"> 
                <h2>Evästekäytännöt</h2>


                <b>Päivitetty: 24.3.2021</b>

                <p>Sivustomme käyttää evästeitä. “Hyväksyn evästeet” -nappia painamalla hyväksyt evästeiden käytön. Evästeet tekevät sivustomme käyttämisestä helppoa, nopeaa ja käyttäjäystävällistä.</p>

                <p>Eväste on pieni tekstitiedosto, jonka internet-selain tallentaa käyttäjän laitteelle. Evästeet asetetaan käyttäjän päätelaitteelle ainoastaan käyttäjän kutsuman sivuston mukana. Vain evästeen lähettänyt palvelin voi myöhemmin lukea ja käyttää evästettä. Evästeet tai muut tekniikat eivät vahingoita käyttäjän päätelaitetta tai tiedostoja, eikä evästeitä voi käyttää ohjelmien käyttämiseen tai haittaohjelmien levittämiseen. Käyttäjää ei voida tunnistaa pelkkien evästeiden avulla.</p>

                <p>Evästeiden käyttökohteet ovat analytiikan, markkinoinnin, viestinnän tehostaminen. Evästeet jakautuvat alaryhmiin: toiminnalliset evästeet, tuotekehitys ja liiketoiminnan raportointi, mainonnan raportointi ja mainonnan kohdentaminen.</p>

                <p>Käytössä on joitakin palvelun toiminnan kannalta pakollisia kolmannen osapuolen työkaluja tai liitännäisiä. Tällaisia työkaluja ovat esimerkiksi sivustoilla olevat sosiaalisten medioiden tai videopalveluiden upotukset tai sosiaalisten medioiden jako- ja tykkäystoiminnallisuudet. Tällaiset kolmannen osapuolen liitännäiset saattavat lisäksi kerätä verkkopalveluiden käyttäjistä tietoja esimerkiksi sisällön suosittelemiseksi tai kävijämäärän seuraamiseksi.</p>

                <p>Voit halutessasi estää selaintasi käyttämästä evästeitä, poistaa tallennetut evästeet tai pyytää selaimeltasi ilmoituksen uusista evästeistä. Löydät ohjeita evästeiden poistoon osoitteesta: <a href='https://www.aboutcookies.org/'>aboutcookies.org</a> Evästeiden käytön estäminen tai niiden poistaminen saattaa haitata joitain sivuston toimintoja.</p>

                <p>Voit lukea lisää evästeistä ja Suomen evästevaatimuksista <a href="https://www.kyberturvallisuuskeskus.fi/fi/toimintamme/saantely-ja-valvonta/luottamuksellinen-viestinta">viestintäviraston verkkosivustolta</a></p>

                <p>Varaamme oikeuden päivittää evästekäytäntöjä esimerkiksi palveluiden kehityksen tai pakottavan lainsäädännön vuoksi.</p>

                <h2>Mitä evästeitä sivustolla käytetään?</h2>
                <table id="cookie-table">
                    <thead>
                        <tr>
                            <th colSpan="2">Nimi</th>
                            <th colSpan="1">Tyyppi</th>
                            <th colSpan="1">Kauppias</th>
                            <th colSpan="1">Kuvaus</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="2">CookieConsent</td>
                            <td colSpan="1">Toiminnalisuus</td>
                            <td colSpan="1">Joensuun Näköpiste</td>
                            <td colSpan="1">Käytetään evästehyväksynnän selvittämiseen</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Evasteet