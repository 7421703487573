import React from 'react';
import MetaTags from 'react-meta-tags';

class Etusivu extends React.Component {
  componentDidMount() {
    document.title = "Joensuun Näköpiste: Optikkopalvelua vuodesta 2013"
  }

  render() {
        return(
            <div className="frontpage">
            <MetaTags>
              <meta name="description" content="Joensuun NäköPiste on Joensuulainen Optikkoliike, jossa panostetaan hyvään palveluun." />
          </MetaTags>
    <div className="main_content">
      <div className="text">
        <h1>Joensuun NäköPiste</h1>
        <p>
          Optikkoliikkeemme sijaitsee Siltakadulla Joensuussa, torin kohdalla ja Kauppakeskus IsoMyyn oven vieressä.
        </p>
        <p>Osoite: Siltakatu 12C </p>
        <p>Ajanvaraus suoraan liikkeestä, puh. 050-3585855 tai <a href="http://vello.fi/joensuun-nakopiste"target="_blank" rel="noopener noreferrer">Vello.fi verkkoajanvarauksen</a> kautta</p>
        <p>Löydät meidät myös <a href="https://www.facebook.com/joensuunnakopiste" target="_blank" rel="noopener noreferrer">Facebookista</a> ja <a href="https://www.instagram.com/nakopiste/?hl=fi" target="_blank" rel="noopener noreferrer">Instagramista</a>.</p>
        
        
        <p>
          Liikkeessämme on siirrettävä pyörätuoliramppi, jonka asennamme paikoilleen tarvittaessa.
        </p>
        <p>
          <b className="bolded">Palvelemme: maanantaista perjantaihin klo 10–17:30 lauantaisin klo 10-14:00.</b><br></br>
          <br></br>
          Lisäksi palvelemme myös sopimuksen mukaan näiden aukioloaikojen ulkopuolella. Olethan yhteydessä meihin, jos haluat varata yksityisen sovitusajan, puhelinnumeromme on 050-3585855.
        </p>
        <p>
        Voit varata ajan tarkastukseen optikoille: Marjo Jormanainen ja (erikseen sovittaessa Irma Rösch).
        </p>

        <p>Me teemme näöntarkastukset, työnäkötarkastukset, silmänpaineen mittaukset, piilolinssien sovitukset ja alustavat kuivasilmätutkimukset ja valitsemme kehykset yhdessä kanssasi.</p>
        <p>Meiltä saat erikoislasit moneen tarpeeseen kuten biljardiin, golfiin, ampumiseen, työlasit näyttöpäätetyöhön ja sivusuojalasit.</p>
      </div>


      <div className="img_container">
        <br></br>
        <img className="Front-IMG" id="Joensuun_NakoPiste_Optikkoliike" alt="Optikkoliike Joensuun NäköPiste" src='https://nakopiste.fi/Resources/etusivu/Optikkoliike_Joensuun_NakoPiste.jpg'></img>
      </div>
    </div>
  </div>
        );
    }
}

export default Etusivu